import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { Box } from '@boopos/design-system';

import { BackButton } from 'components/BackButton';
import { CreditReportFormWithData } from 'components/CreditReport';
import { LayoutHideMenu } from 'components/Layout';
import { IQualificationStatus } from 'components/QualificationStatus';
import {
  AnimatedScreenSlideWrapper,
  SubNavigationFirstLevelContent,
} from 'components/ScreenLayout';
import { SubScreenTitle } from 'components/SubScreenTitle';

import api from 'lib/api';

import { routePaths } from 'router/routePaths';

export const CreditReportScreen = () => {
  const { t } = useTranslation();
  const { mutate } = useSWR<IQualificationStatus>(api.getQualification);

  const title = t('QualificationScreenMenu:credit_report_section');

  const onSuccess = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return (
    <AnimatedScreenSlideWrapper>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <LayoutHideMenu hideOn="all" shouldShowMenuOnUnmount={false} />
      <SubNavigationFirstLevelContent>
        <BackButton to={routePaths.accountQualification} />
        <SubScreenTitle>{title}</SubScreenTitle>
        <Box css={{ mt: '2.4rem' }} />
        <CreditReportFormWithData
          showResidencyRadios={true}
          submitAction={t('CreditReportScreen:credit_report_save')}
          onSuccess={onSuccess}
          cache={false}
        />
      </SubNavigationFirstLevelContent>
    </AnimatedScreenSlideWrapper>
  );
};
